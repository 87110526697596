import React, { useEffect,useState } from 'react';
import { useNavigate , NavLink } from 'react-router-dom';

function Join() {
    const [token, setToken] = useState();
    const [accountNo, setAccountNo] = useState();
    const [matchData, setMatchData] = useState({
        prefix: 'PCX',
        id: '',
        Token:'',
        Note:''
    });
    const [selectedBetId, setSelectedBetId] = useState(null);
    const [matchCode, setMatchCode] = useState('');
    const [transaction, setTransactionData] = useState({
        AccountNo: '',
        Amount:'',
        TransactionCode: '',
        Token: ''
    });

    const [entryData, setEntryData] = useState({
        Id: '',
        Token: '',
        Note: '',
        betId:0
    });

    const handleBetOptionClick = (option) => {
        setEntryData(prevState => ({
            ...prevState,
            Note: `${option.name}`, // Update Note
            betId: option.id // Update betId
        }));
        setSelectedBetId(option.id); // Set the selected bet ID
    };

    ///const [game, setGame] = useState([]);
    const [findingMatch, setMatchSearch] = useState(false); 
    const [confirmingPayment, setPaymentConfirmation] = useState(false); 
    const [startPlaying, setStartPlaying] = useState(false); 
    const [match, setMatch] = useState([]);
    const [matchOn, setMatchMode] = useState(false); 
    const [balance, setBalance] = useState(false); 
    ////const { id } = useParams();
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const jsonData = localStorage.getItem('pacxosData');
        if (jsonData) {
            const tokenJson = JSON.parse(jsonData);
            console.log(tokenJson);
            
            if(!token){
                setToken(tokenJson.token);
            }else{
                setAccountNo(tokenJson.phoneNumber);

                const matchCodeData = localStorage.getItem('matchCode');
                if(matchCodeData){
                    setMatchCode(matchCodeData);
    
                    findReferredMatch(matchCodeData,tokenJson.token,tokenJson.phoneNumber);
                }
            }
        } else {
            setToken('');
        }
    }, [token]);
    
    const handleChange = (e) => {
        setMatchData({ ...matchData, [e.target.name]: e.target.value });
    };

    const handleTransactionChange = (e) => {
        setTransactionData({ ...transaction, [e.target.name]: e.target.value });
    };

    const handleEntryChange = (e) => {
        setEntryData({ ...entryData, [e.target.name]: e.target.value });
    };

    const findReferredMatch = async (matchCode,sessionToken,sessionAccountNo) => {
        setMatchSearch(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/JoinMatch?id=${matchCode}&token=${sessionToken}`);

            if(response.ok){
                const data = await response.json();
                setMatch(data);

                if(data.gameId==2){
                    setEntryData(prevState => ({
                        ...prevState,
                        ///Note: `WIN`, // Update Note
                        betId: 0 // Update betId
                    }));
                    setSelectedBetId(0);
                }

                fetchWallet(sessionToken);
                setMatchMode(true);

                setMatchData({ ...matchData, id: sessionAccountNo });
            }else{
                const errorResponse = await response.text();
                setError('Failed. '+errorResponse);
                setMatchSearch(false);
            }
            
            ///fetchMatch(data);
        } catch (error) {
            setError('Failed. ');
            setMatchSearch(false);
            ////console.error('Error:', error);
        }

        localStorage.removeItem('matchCode');
    };

    const startMatchSubmit = async (e) => {
        e.preventDefault();
        const updatedMatchData = { ...matchData, id:"PCX"+matchData.id,Token: token };
        ///console.log(matchData);
        // console.log(updatedMatchData);
        setMatchSearch(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/JoinMatch?id=${updatedMatchData.id}&token=${updatedMatchData.Token}`);

            if(response.ok){
                const data = await response.json();
                setMatch(data);

                if(data.gameId==2){
                    setEntryData(prevState => ({
                        ...prevState,
                        ///Note: `WIN`, // Update Note
                        betId: 0 // Update betId
                    }));
                    setSelectedBetId(0);
                }
                fetchWallet(updatedMatchData.Token);
                setMatchMode(true);

                setMatchData({ ...matchData, id: accountNo });
            }else{
                const errorResponse = await response.text();
                setError('Failed. '+errorResponse);
                setMatchSearch(false);
            }
            
            ///fetchMatch(data);
        } catch (error) {
            setError('Failed. ');
            setMatchSearch(false);
            ////console.error('Error:', error);
        }
    };

    const paymentConfirmationSubmit = async (e) => {
        e.preventDefault();
        const updatedTransactionData = { ...transaction, AccountNo:accountNo,Amount:match.stakeAmt,Token: token };
        // console.log(transaction);
        // console.log(updatedTransactionData);
        setPaymentConfirmation(true);
        try {
            // const response = await fetch(`${process.env.REACT_APP_API_URL}/api/Wallet`,{
            //     method: 'POST',
            //     headers: {
            //       'Content-Type': 'application/json'
            //     },
            //     body: JSON.stringify(updatedTransactionData)
            //   });
            // if(response.ok){
            //     ///const data = await response.json();
                
            //     fetchWallet(updatedTransactionData.Token);
            // }else{
            //     const errorResponse = await response.text();
            //     setError('Failed. Try again later');
            //     setPaymentConfirmation(false);
            // }
            fetchWallet(updatedTransactionData.Token);
            ///fetchMatch(data);
        } catch (error) {
            setError('Failed. Try again later');
            setPaymentConfirmation(false);
            ///console.error('Error:', error);
        }
    };

    const fetchWallet = async (token) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/Wallet?token=${token}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setBalance(data);
        } catch (error) {
            ////console.error('Error fetching game:', error);
        }
    };

    const matchEntryPlay = async (e) => {
        const updatedEntryData = { ...entryData, Id:match.matchRef,Token: token };

        // console.log("Note:"+updatedEntryData.Note);
        // console.log("Data :"+updatedEntryData);
        // console.log("Data :"+JSON.stringify(updatedEntryData));

        if(!updatedEntryData.Note){
            setError('Enter prediction');
            return;
        }
        
        try {
            playMatch(updatedEntryData);
        } catch (error) {
            setError('Failed. Try again later');
        }
    };

    const matchEntrySubmit = async (e) => {
        e.preventDefault();
        ///console.log("Note:"+entryData.stakeNote);
        const updatedEntryData = { ...entryData, Id:match.matchRef,Token: token };

        if(!updatedEntryData.Note){
            setError('Select your stake option');
            return;
        }
        
        try {
            playMatch(updatedEntryData);
        } catch (error) {
            setError('Failed. Try again later');
        }
    };
    
    const playMatch = async (matchEntryData) => {
        ///console.log(matchEntryData);
        setStartPlaying(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/Play`, {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json'
                },
                body: JSON.stringify(matchEntryData)
            });
            ///const response = await fetch(`${process.env.REACT_APP_API_URL}/api/Play?id=${id}&note="${playerNote}"&token=${token}`);
            if (!response.ok) {
                const errorResponse = await response.text();
                setError('Failed. Try again later');
                throw new Error('Failed. Try again later');
            }
            ///const data = await response.json();
            fetchWallet(matchEntryData.Token);
            navigate('/live');
        } catch (error) {
            setStartPlaying(false);
            ////console.error('Error fetching game:', error);
        }
    };

    function formatPeriodDate(dateTimeString) {
        const date = new Date(dateTimeString);
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return new Intl.DateTimeFormat('en-US', options).format(date);
    }

    return (
        <div className="game-details mt-5 pt-5">
            {matchOn?
                <div>
                    <div className='d-flex justify-content-between'>
                        <div>
                            <p className="size-10 mb-1"><span className='text-danger'><i className="bi bi-record-circle size-16 me-1" />{match.matchStatusTitle}</span></p>
                            <p className='size-16 fw-bold mb-2'>{match.matchRef}</p>
                        </div>
                        <NavLink to={`/`}  className="btn btn-default btn-44 shadow-none">
                            <i class="bi bi-arrow-left"></i>
                        </NavLink>
                    </div>
                    <hr className='my-2'/>
                    <div className='d-flex justify-content-between'>
                        <p className="size-12 mb-2"><span className='text-dark'><i className="bi bi-person-circle size-16 me-1" />{match.moderatorName}</span></p>
                        <p className="text-secondary text-muted size-12 mb-2">{match.createdDate?formatPeriodDate(match.createdDate):<></>}</p>
                    </div>
                    {match.matchDescription ? <p className="text-secondary mb-3 size-14"><em>{match.matchDescription}</em></p>:<></>}
                    
                    {match.betOptions && match.betOptions.map((option, index) => {
                        const badgeColors = ['bg-blue text-white', 'bg-indigo text-white', 'bg-cyan text-white', 'bg-pink text-white', 'bg-orange text-white'];
                        // Determine the color based on the index
                        const badgeColor = badgeColors[index % badgeColors.length];
                        
                        return (
                            <span key={option.id} className={`badge ${badgeColor} rounded-10 me-2 mb-2`}>
                                {option.name}
                            </span>
                        );
                    })}

                    <div className='row'>
                        {match.players!=null?
                            <div className='col-4 mb-2'>
                                <p className="text-dark size-16 fw-bold mb-0">{match.players}</p>
                                <p className="text-muted size-12 fw-bold mb-0">Players</p>
                            </div>
                            :
                            <></>
                        }
                        {match.stakeAmt!=null?
                            <div className='col-4 mb-2'>
                                <p className="text-dark size-16 fw-bold mb-0">Ksh {match.stakeAmt}</p>
                                <p className="text-muted size-12 fw-bold mb-0">Stake</p>
                            </div>
                            :
                            <></>
                        }
                        {match.stakeEscrowAmt!=null?
                            <div className='col-4 mb-2'>
                                <p className="text-dark size-16 fw-bold mb-0">Ksh {match.stakeEscrowAmt}</p>
                                <p className="text-muted size-12 fw-bold mb-0">Escrow</p>
                            </div>
                            :
                            <></>
                        }
                        {match.moderatorFee!=null?
                            <div className='col-4 mb-2'>
                                <p className="text-dark size-16 fw-bold mb-0">Ksh {match.moderatorFee}</p>
                                <p className="text-muted size-12 fw-bold mb-0">Moderator</p>
                            </div>
                            :
                            <></>
                        }
                        {match.moderatorChargesAmt!=null?
                            <div className='col-4 mb-2'>
                                <p className="text-dark size-16 fw-bold mb-0">Ksh {match.moderatorChargesAmt}</p>
                                <p className="text-muted size-12 fw-bold mb-0">Charge</p>
                            </div>
                            :
                            <></>
                        }
                        {match.winAmt!=null?
                            <div className='col-4 mb-2'>
                                <p className="text-dark size-16 fw-bold mb-0">Ksh {match.winAmt}</p>
                                <p className="text-muted size-12 fw-bold mb-0">Win</p>
                            </div>
                            :
                            <></>
                        }
                    </div>
                    <div className="border rounded bg-light p-3 my-3">
                        <div className="d-flex gap-2 justify-content-between align-items-center">
                            <b>Wallet Balance</b>
                            <b className="text-muted">Ksh {balance}</b>
                        </div>
                    </div>
                    
                    {balance>=match.stakeAmt?
                        <div className='text-center text-success'>
                            {startPlaying?
                                <p className='text-center my-2 fw-bold'>WAIT, Joining Match</p>
                            :
                                <>
                                    {match.gameId==1 ? 
                                        <>
                                            {error && <p className="text-danger my-3">{error}</p>}
                                            <form onSubmit={matchEntrySubmit} className='mb-5'>
                                                <p className='text-success text-center'>
                                                    You have sufficient funds to play this match. Select your bet option and click PLAY.
                                                </p>
                                                {match.betOptions.map((option, index) => {
                                                    const badgeColors = ['border-blue text-blue', 'border-indigo text-indigo', 'border-cyan text-cyan', 'border-pink text-pink', 'border-orange text-orange'];
                                                    const badgeSelected = ['bg-blue text-white', 'bg-indigo text-white', 'bg-cyan text-white', 'bg-pink text-white', 'bg-orange text-white'];
                                                    // Determine the color based on the index
                                                    ///const badgeColor = badgeColors[index % badgeColors.length];
                                                    const badgeColor = selectedBetId === option.id
                                                    ? badgeSelected[index % badgeSelected.length] // Danger background and text for selected option
                                                    : badgeColors[index % badgeColors.length]; 
                                                    
                                                    return (
                                                        <div 
                                                            key={option.id} 
                                                            className={`${badgeColor} border rounded-10 p-2 mt-2 mb-3 d-flex justify-content-between`}
                                                            onClick={() => handleBetOptionClick(option)} // Handle click event
                                                            style={{ cursor: 'pointer' }}>
                                                            <span className="fw-bold">{option.name}</span>
                                                            {
                                                                selectedBetId === option.id
                                                                ? <i class="bi bi-check-square-fill"></i> // Danger background and text for selected option
                                                                : <i class="bi bi-square"></i>
                                                            }
                                                        </div>
                                                    );
                                                })}
                                                {/* <textarea name="Note" className="form-control mb-4 p-3 mt-3" id="Note" rows="3" onChange={handleEntryChange} placeholder='Enter stake note...' value={entryData.Note}/> */}
                                                {/* <button onClick={() => playMatch(match.matchRef, token)} className='btn btn-default btn-lg w-100 my-3 mb-5 text-center'>PLAY</button> */}
                                                <button type='submit' className='btn btn-default rounded-3 btn-lg w-100 my-3 mb-5 text-center'>PLAY</button>
                                            </form>
                                        </>
                                    :
                                        <>
                                
                                            {error && <p className="text-danger my-3">{error}</p>}
                                            <p className='text-success text-center my-3'>
                                                You have sufficient funds to play this match, Enter your prediction and Click PLAY.
                                            </p>

                                            <textarea name="Note" className="form-control mb-4 p-3 mt-3" id="Note" rows="3" onChange={handleEntryChange} placeholder='Enter stake note...' value={entryData.Note}/>
                                            <button type='button' onClick={() => matchEntryPlay()} className='btn btn-default rounded-3 btn-lg w-100 my-3 mb-5 text-center'>PLAY</button>
                                        </>
                                    }
                                </>
                            }
                        </div>
                        :
                        <div className='mb-5 pb-5'>
                            <p className='text-center text-danger'>You have insufficient balance. Top-Up your wallet to play.</p>
                            <p>Go to M-Pesa, <b>Lipa na M-Pesa</b> <span className='mx-2'>-</span> <b>Paybill</b></p>
                            <p>Enter paybill number, <b>4356494</b></p>
                            <p>Enter account number, <b>{accountNo}</b></p>
                            <p>Enter amount, <b>{match.stakeAmt}</b></p>
                            <p>Enter your pin and complete transaction. After recieving the M-pesa confirmation message, Enter the M-Pesa code below and click <b>Validate</b> to load your wallet</p>
                            <form onSubmit={paymentConfirmationSubmit}>
                                <div className='d-flex align-items-center gap-2'>
                                    <div className="form-floating flex-grow-1 is-valid mb-3">
                                        <input type="text" className="form-control" placeholder="M-Pesa Code" name='TransactionCode' value={transaction.TransactionCode} onChange={handleTransactionChange} id="TransactionCode" />
                                        <label htmlFor="TransactionCode">Payment Reference</label>
                                    </div>
                                    {confirmingPayment?
                                        <button type='button'  className="btn btn-default btn-44 shadow-none">
                                            ...
                                        </button>
                                    :
                                        <button type='submit'  className="btn btn-default btn-44 shadow-none">
                                            <i class="bi bi-check-lg"></i>
                                        </button>
                                    }
                                    
                                </div>
                                
                                {error && <p className="text-danger my-3">{error}</p>}
                                
                            </form>
                        </div>
                    }
                </div>
            :
                <form onSubmit={startMatchSubmit}>
                    <h6 className="mb-4 text-center"><span className="text-secondary fw-light me-2">Find</span>Match</h6>  
                    {findingMatch?
                        <p className='text-center p-3'><em>Finding match...</em></p>
                    :
                    <div className='d-flex align-items-center gap-2'>
                        <div className="form-floating flex-grow-1 is-valid mb-3">
                            <div className='row'>
                                <div className='col-4'>
                                    <input type="text" className="form-control" placeholder="Prefix" name='prefix' value={matchData.prefix} readOnly id="matchid" />
                                </div>
                                <div className='col-8'>
                                    <input type="number" className="form-control col-8" placeholder="Enter Code" name='id' value={matchData.id} onChange={handleChange} id="matchid" />
                                </div>
                            </div>
                            {/* <label htmlFor="matchid">Match Code</label> */}
                        </div>
                        <button type='submit'  className="btn btn-default btn-44 px-3 shadow-none">
                            <i class="bi bi-search"></i>
                        </button>
                    </div>
                    }
                    
                    {error && <p className="text-danger my-3">{error}</p>}
                    
                </form>
            }
        </div>
    );
}

export default Join;

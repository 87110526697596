import React, { useEffect,useState } from 'react';
import { NavLink } from 'react-router-dom';

function Games() {
    const [games, setGames] = useState([]);

    useEffect(() => {
        fetchGames();
    }, []);

    const fetchGames = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/Game`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setGames(data);
        } catch (error) {
            console.error('Error fetching games:', error);
        }
    };

    return (
        <>
            <div>
                <div className='py-5'>
                    <div className='row'>
                        {games.map((game) => (
                            <div key={game.id} className='col-12 col-md-6 '>
                                <div className="card mt-5 mb-4">
                                    <div className="card-body p-4">
                                        <div className='d-flex justify-content-between'>
                                            <div>
                                                <p className='mb-0 fw-bold size-10'>#{game.gameRef}</p>
                                                <h5 className='text-center mb-2'>{game.gameName}</h5>
                                            </div>
                                            <NavLink to={`/game/${game.id}`}  className="btn btn-default btn-44 shadow-sm">
                                                <i class="bi bi-arrow-right"></i>
                                            </NavLink>
                                        </div>
                                        <div className='d-flex flex-column gap-2 justify-content-between'>
                                            <div className="border-top mt-3 pt-2 flex-grow-1">
                                                <small className='text-center'>{game.gameDesc}</small>
                                            </div>
                                            {/* <div className="d-flex gap-2 justify-content-between align-items-center">
                                                <p>Players</p>
                                                <div className="dashed-line flex-grow-1 my-2" />
                                                <p className="text-muted">{game.minPlayers===game.maxPlayers?<span>2</span>:<b><span>{game.minPlayers}</span><span> to </span><span>{game.maxPlayers}</span></b>}</p>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    
                </div>
                {/* <div className='mb-3 text-center'>
                    <img src="pacxos.svg" height='50px' alt />
                </div> */}
            </div>
        </>
    );
}

export default Games;